import services from "./index";
import store from "@/store";
import qs from "querystring";

export default {
    getConfig() {
        return {
            headers: {
                authorization: `Bearer ${store.getters.jwtToken}`,
                //"x-tenant-id": process.env.VUE_APP_TENANT,
            }
        };
    },
    
    get(id) {
        return services.http.get(`/v1.0/profile/groups/${id}`, this.getConfig());
    },
    
    getTeamNamesInEvent(eventId) {
        return services.http.get(`/v1.0/groups/events/${eventId}/teams`, this.getConfig());
    },

}